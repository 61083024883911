<template>
  <div id="dashboardVehicle" class="Content-Page fixbug">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between">
        <div class="N-Page T-size-36">Flight DashBoard</div>


        <div class="box-S2 flex-between noPadding">
          <div class="box-S2 noPadding">
            <v-select class="hideMessage" :items="itemsListCustomer" v-model="selectcustomer" item-text="o_name"
              item-value="o_name" label="Customer" return-object @change="customerChange"></v-select>
          </div>
          <div class="box-S2 noPadding">
            <div></div>
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y>
              <template v-slot:activator="{ on }">

                <v-text-field class="hideMessage input-date" v-model="picker" label="Select Date" readonly
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="picker" type="month" @change="Render(picker)"
                @input="menu = false"></v-date-picker>
            </v-menu>
          </div>
          <div class="box-S2 noPadding">
            <div></div>
            <v-select class="hideMessage" :items="itemsListDeparture" v-model="selectDeparture" item-text="ap_name"
              item-value="ap_id" label="Departure From" return-object @change="departureFromChange"></v-select>
          </div>
        </div>
      </div>
    </div>

    <div id="Dashboard" class="wrap-Main">
      <div class="box-S1">
        <div class="B-carddetail N-Page">
          Total Flight Today<br />
          {{ count_flight_today }}
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail N-Page">
          Total Flight in Month<br />
          {{ count_flight }}
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail N-Page">
          Passenger Out Bound<br />
          {{ passenger_out }}
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail N-Page">
          Passenger In Bound<br />
          {{ passenger_in }}
        </div>
      </div>

      <div class="box-S4 checkresponse">
        <div class="B-carddetail">
          <diV v-if="tasks.length > 0">
            <gantt-elastic :options="options" :tasks="tasks" @tasks-changed="tasksUpdate"
              @options-changed="optionsUpdate" @dynamic-style-changed="styleUpdate">
              <gantt-header> </gantt-header>
            </gantt-elastic>
          </diV>
        </div>
      </div>

      <div class="box-S2">
        <div class="B-carddetail">
          <highcharts :options="chartOptionsPIEClient"></highcharts>
        </div>
      </div>
      <div class="box-S2">
        <div class="B-carddetail">
          <highcharts :options="chartOptionSumHour"></highcharts>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import GanttElastic from "gantt-elastic";
import GanttHeader from "gantt-elastic-header";
import dayjs from "dayjs";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import fillerdate from "../utils/filterdate";
import { sl } from "date-fns/locale";
import { Chart } from "highcharts-vue";
import feathersClientVan from "../plugins/feathers-client";
import { color } from "highcharts";
import { dataStateChange } from '@syncfusion/ej2-grids';

import {
  startOfDay,
  endOfDay,
  format,
  addHours,
  addMinutes,
  differenceInMinutes,
} from "date-fns";
// just helper to get current dates
function getDate(hours) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const timeStamp = new Date(
    currentYear,
    currentMonth,
    currentDay,
    0,
    0,
    0
  ).getTime();
  return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
}
let tasks = [];
let sumHelicopter = [];
let sumFlight = [];
let options = {
  taskMapping: {
    progress: "percent",
  },
  maxRows: 100,
  maxHeight: 500,
  XScale: 2,
  style: {
    "gantt-elastic__main-view": {
      "background-color": "#333232"
    }
  },
  title: {
    label: "Your project title as html (link or whatever...)",
    html: false,
  },
  row: {
    height: 24,
  },
  times: {
    timeScale: 60 * 1000,
    timeZoom: 2, //*
    timePerPixel: 0,
    firstTime: null,
    lastTime: null,
    firstTaskTime: 0,
    lastTaskTime: 0,
    totalViewDurationMs: 0,
    totalViewDurationPx: 0,
    stepDuration: "day",
    steps: [],
  },
  calendar: {
    hour: {
      display: true,
    },
  },
  chart: {
    progress: {
      bar: false,
    },
    expander: {
      display: true,
    },
  },
  taskList: {
    expander: {
      straight: false,
    },
    columns: [
      {
        id: 1,
        label: "Flight No",
        value: "id",
        width: 120

      },
      {
        id: 1,
        label: "Customer",
        value: "c_name",
        width: 80,
      },
      {
        id: 2,
        label: "AirCraft Name",
        value: "label",
        width: 150,
        expander: true,
        html: true,
      },
      {
        id: 3,
        label: "Start Date",
        value: task => dayjs(task.start).format("YYYY-MM-DD HH:mm"),
        width: 120,
      },
      {
        id: 4,
        label: "Stop Date",
        value: task => dayjs(task.end).format("YYYY-MM-DD HH:mm"),
        width: 120,
      },
    ],
  },
  locale: {
    name: "en",
    Now: "Now",
    "X-Scale": "Zoom-X",
    "Y-Scale": "Zoom-Y",
    "Task list width": "Task list",
    "Before/After": "Expand",
    "Display task list": "Task list",
  },
};

export default {
  name: "Gantt",
  components: {
    GanttElastic,
    GanttHeader,
  },
  data() {
    return {
      itemsListFlight: [],
      Datadate: new Date().toISOString().substr(0, 10),
      //Add
      companyid: 0,
      userdata: "",
      itemsListCustomer: [],
      selectcustomer: "",
      o_id: 0,
      tasks,
      sumHelicopter,
      count_flight: 0,
      count_flight_today: 0,
      passenger_in: 0,
      passenger_out: 0,
      options,
      dynamicStyle: {},
      flightList: [],
      picker: {},
      sumFlight: [],
      itemsListDeparture: [],
      selectDeparture: {},
      dateSelect: new Date().toISOString().substr(0, 7),
      chartOptionsPIEClient: {},
      chartOptionSumHour: {},

    };
  },
  async mounted() {
    this.picker = this.dateSelect;
    await this.renderListDeparture();
    await this.selectDepartureFrom();
    await this.reloaddata(0);
    await this.SelectListCustomer();
    await this.chartSumhour(0);
    await this.chartSumClient(0);
  },
  methods: {
    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }

    },
    async selectDepartureFrom() {
      try {
        let departureChange = await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },
    async departureFromChange(arg) {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
      await this.reloaddata(this.companyid);
      await this.chartSumhour(this.companyid);
      await this.chartSumClient(this.companyid);
    },
    Rendertasks(
      FilghtId,
      aircrafts,
      flightdate,
      flightduration,
      companyname,
      dateend
    ) {
      console.log(
        flightdate,
        dateend
      );
      this.tasks.push({
        id: FilghtId,
        label: aircrafts,
        c_name: companyname,
        start: flightdate,
        end: dateend,
        duration: flightduration,
        percent: 100,
        type: "milestone",
      });
    },
    tasksUpdate(tasks) {
      this.tasks = tasks;
    },
    async customerChange(arg) {
      this.selectcustomer = arg.o_name;
      this.companyid = arg.o_id;
      await this.reloaddata(arg.o_id);
      await this.chartSumhour(arg.o_id);
      await this.chartSumClient(arg.o_id);
    },
    optionsUpdate(options) {
      this.options = options;
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    },
    async Render(Select) {
      await this.reloaddata(this.companyid);
      await this.chartSumhour(this.companyid);
      await this.chartSumClient(this.companyid);
    },
    async SelectListCustomer() {
      try {
        const q = {};
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }

        var res = await feathersClientUOA.service("customer").find({ query: q });
        res.data.forEach(element => {
          if (this.userdata.r_id == 4 || this.userdata.r_id == 5 || this.userdata.r_id == 9 || this.userdata.r_id == 15) { //Show All only flight
            this.itemsListCustomer.push({ o_id: 0, o_name: "All" });
          }
          if (element.o_id != 1) {
            this.itemsListCustomer.push({ o_id: element.o_id, o_name: element.o_name });
            this.selectcustomer = this.itemsListCustomer[0];
          }
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async reloaddata(oid) {
      try {
        var DateStart = format(startOfDay(new Date()), "yyyy-MM-dd HH:mm:ss");
        var DateEnd = format(endOfDay(new Date()), "yyyy-MM-dd HH:mm:ss");
        let queryViewFlight = {};
        if (oid != 0) { queryViewFlight.o_id = oid; }
        queryViewFlight.ap_id = this.selectDeparture.ap_id;
        queryViewFlight.ap_depart_date = {
          $gte: DateStart,
          $lte: DateEnd,
        };
        var reqData = await feathersClientUOA.service("viewflight").find({ query: queryViewFlight });
        this.count_flight_today = reqData.total;
      } catch (error) {
        alert(error)
      }

      console.log("reload")
      this.passenger_in = 0;
      this.passenger_out = 0;
      this.tasks = [];
      //Add
      this.userdata = this.$CurrentUser.GetCurrentUser();
      const q = {};
      q.month = this.picker.substr(5, 2);
      q.year = this.picker.substr(0, 4);
      q.retire = 0;
      if (this.userdata.r_id == "2")
        q.o_id = this.userdata.o_id;
      else
        q.o_id = oid;
      q.ap_id = this.selectDeparture.ap_id;
      this.itemsListFlight = [];
      try {
        var res = await feathersClientUOA
          .service("flightselect")
          .find({ query: q });

        //console.log("417",res[0].data[0])
        this.flightList = res[0].data[0].filter(
          (x) =>
            x.ap_depart_date.substr(5, 2) == q.month &&
            x.ap_depart_date.substr(0, 4) == q.year
        );
        
        this.count_flight = this.flightList.length;
        //console.log("424",this.flightList)
        // this.count_flight_today = this.flightList.filter(
        //   (x) =>
        //     x.ap_depart_date.substr(5, 2) == q.month &&
        //     x.ap_depart_date.substr(0, 4) == q.year &&
        //     x.ap_depart_date.substr(8, 2) == q.day
        // )

        this.flightList = this.flightList.sort((a, b) => (a.ap_depart_date > b.ap_depart_date) ? -1 : 1)
        console.log("flightList")
        //console.log(this.flightList)
        for (var i = 0; i < this.flightList.length; i++) {
          for (var j = 0; j < this.flightList[i].passenger.length; j++) {
            if (this.flightList[i].passenger[j].pt_id == "2") {
              this.passenger_in = this.passenger_in + 1;
            } else {
              this.passenger_out = this.passenger_out + 1;
            }
          }
          var datestart =
            this.flightList[i].ap_depart_date.substr(0, 10) +
            " " +
            this.flightList[i].ap_depart_date.substr(11, 5);
          var dateend =
            this.flightList[i].ap_dest_date.substr(0, 10) +
            " " +
            this.flightList[i].ap_dest_date.substr(11, 5);

          //console.log(dateend);
          const date1 = dayjs(dayjs(dateend).format("YYYY-MM-DD HH:mm"));

          const date2 = dayjs(dayjs(datestart).format("YYYY-MM-DD HH:mm"));
          var duration = date1.diff(date2);
          // console.log(datestart);
          // console.log(duration);
          var aircraftname = null
          if (this.flightList[i].aircrafts != null) {
            aircraftname = this.flightList[i].aircrafts.ac_name;
          }
          //console.log(JSON.stringify(this.flightList))
          this.Rendertasks(
            this.flightList[i].f_flight_no,
            aircraftname,
            datestart,
            duration,
            this.flightList[i].customer.o_name,
            dateend
          );
        }
      } catch (error) {
        alert(error);
      }


    },
    async chartSumhour(oid) {
      const q = {};

      q.month = this.picker.substr(5, 2);
      q.year = this.picker.substr(0, 4);
      if (this.userdata.r_id == "2")
        q.customerid = this.userdata.o_id;
      else
        q.customerid = oid;
      q.ap_id = this.selectDeparture.ap_id;

      //alert(JSON.stringify(q))
      this.sumHelicopter = [];
      let inDTOdata = await feathersClientUOA
        .service("sumheliuse")
        .find({ query: q });
      //console.log(inDTOdata);
      try {
        for (var i = 0; i < inDTOdata.length; i++) {
          this.sumHelicopter.push({ name: inDTOdata[i].ac_name, y: inDTOdata[i].sumary, drilldown: null })
        }
      }
      catch (error) {
        alert(error);
      }
      //console.log(this.sumHelicopter);

      this.chartOptionSumHour = {
        chart: {
          type: "column",
          backgroundColor: "#333232",
          borderRadius: "5px"
        },
        colors: [
          "#D91F20",
          "#3187EA",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4"
        ],
        title: {
          text: "Total Helicopter Use In Month",
          style: {
            color: "#ffffff"
          }
        },
        xAxis: {
          type: "category",
          labels: {
            format: "{value:%Y-%m-%d}",
            rotation: 45,
            align: "left",
            style: {
              color: "#ffffff"
            }


          }
        },
        yAxis: {
          title: {
            text: 'Total percent market share',
            style: {
              color: "#ffffff"
            }
          },
          labels: {
            style: {
              color: "#ffffff"
            }
          }
        },
        legend: {
          enabled: false,
          itemStyle: {
            color: "#ffffff"
          },
          align: "right",
          x: -30,
          verticalAlign: "top",
          y: 25,
          floating: true,
          backgroundColor: "#333232",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: false
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",

        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true
            }
          },
          series: {
            borderColor: "#333232"
          }
        },
        series: [
          {
            name: "name",
            colorByPoint: true,
            data: this.sumHelicopter
          }
        ]
      };
    },
    async chartSumClient(oid) {
      this.sumFlight = [];
      const q = {};
      q.month = this.picker.substr(5, 2);
      q.year = this.picker.substr(0, 4);
      if (this.userdata.r_id == "2")
        q.customerid = this.userdata.o_id;
      else
        q.customerid = oid;
      q.ap_id = this.selectDeparture.ap_id;
      this.itemsListFlight = [];

      var res = await feathersClientUOA
        .service("sumflight")
        .find({ query: q });
      //console.log(res)
      try {
        var count = 0;
        for (var i = 0; i < res.length; i++) {
          count = count + res[i].sumary;
        }
        for (var i = 0; i < res.length; i++) {
          var cal_percent = (res[i].sumary * 100) / count;
          this.sumFlight.push({ name: res[i].o_name, y: res[i].sumary })
        }
      }
      catch (error) {
        alert(error);
      }

      this.chartOptionsPIEClient = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          backgroundColor: "#333232",
          borderRadius: "5px"
        },
        colors: [
          "#D91F20",
          "#3187EA",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4"
        ],
        title: {
          text: "Total Flight In Month",
          style: {
            color: "#ffffff"
          }
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
        },
        legend: {
          itemStyle: {
            color: "#ffffff"
          }
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y} Flight',
              style: {
                color: "#ffffff"
              }
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: "Total Flight",
            colorByPoint: true,
            data: this.sumFlight,
            borderColor: "#333232"
          }
        ]
      };
    },

  },

};
</script>
<style scoped>
.fixbug {
  width: calc(100% - 300px);
}

.checkresponse {
  display: block;
}

@media screen and (max-width: 600px) {
  .fixbug {
    width: 100%;
  }

  .checkresponse {
    display: none;
  }
}
</style>
<style>
.gantt-elastic__task-list-header {
  margin-bottom: 3px !important;
}

.gantt-elastic__chart-calendar-container {
  margin-bottom: 3px !important;
}

.gantt-elastic__task-list-header-column {
  background-color: #333232 !important;

}

.gantt-elastic__task-list-header-column {
  background-color: #333232 !important;
}

.gantt-elastic__task-list-item-value-wrapper {
  background-color: #333232 !important;
}

.gantt-elastic__task-list-item-value {
  background-color: #333232 !important;
  color: white !important;
}

.gantt-elastic__chart-graph-svg {
  background-color: #333232 !important;
  color: white !important;
}

.gantt-elastic__calendar-row-rect-child {
  background-color: #333232 !important;
  color: white !important;
}

.gantt-elastic__calendar-row-rect-child--month {
  background-color: #333232 !important;
  color: white !important;
}

.gantt-elastic__chart-days-highlight-rect {
  background-color: #333232 !important;
  color: white !important;
  fill: #333232 !important;
}

.gantt-elastic__calendar-row-rect-child--hour {
  color: white !important;
}

.gantt-elastic__calendar-row-text {
  color: #FFFFFF !important;
}

.gantt-elastic__grid-line-horizontal {
  stroke: #FFFFFF !important;
}
</style>